<script setup lang="ts">
interface Props {
	image?: string
	alt?: string
	frame?: string
	size?: 'sm' | 'md' | 'lg' | 'xl' | Array<'sm' | 'md' | 'lg' | 'xl'>
	hasPlaceholder?: boolean
}

const props = withDefaults(defineProps<Props>(), {
	hasPlaceholder: true,
});
</script>

<template>
	<span class="user-frame" :class="{ 'user-frame--thumbnail': props.size === 'sm' }">
		<span class="user-frame__frame-wrapper" v-if="props.frame">
			<FirebasePicture
				class="user-frame__frame-picture"
				img-class="user-frame__frame"
				bucket="frame"
				:name="props.frame"
				:size="props.size"
				aria-hidden="true"
			/>
		</span>
		<span class="user-frame__image-wrapper" v-if="props.image">
			<FirebasePicture
				class="user-frame__picture"
				img-class="user-frame__image"
				bucket="user"
				:name="props.image"
				:alt="props.alt"
				:size="props.size"
			/>
		</span>
		<span class="user-frame__placeholder" v-else-if="props.hasPlaceholder">
			<Icon name="user" />
		</span>
	</span>
</template>
